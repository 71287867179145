import React from "react";

export default function Spacer({ size = "", col, row }) {
  const basis = size === "small" ? 5 : size === "large" ? 50 : 15;
  if (col) {
    return <div style={{ minHeight: basis }} />;
  }
  if (row) {
    return <div style={{ minWidth: basis }} />;
  }

  // problem with flexBasis is it doesn't expand the parent's witdh / height, making some content disappear on overflow
  return <div style={{ flexBasis: basis, flexShrink: 0 }} />;
}
