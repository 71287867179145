import React, { forwardRef } from "react";
import * as palet from "./palet";

import InputUnstyled from "./unstyled/Input";

function Input({ className = "", ...props }, ref) {
  return (
    <InputUnstyled
      ref={ref}
      className={`border border-gray-300 text-gray-900 rounded p-2 ${className}`}
      {...props}
    />
  );
}

export default forwardRef(Input);
