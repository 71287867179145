import React, { forwardRef } from "react";
import Icon from "./Icon";

function Switch({ theme = "gray", value, children, ...props }, ref) {
  return (
    <label
      className={`clickable bg-${theme}-200 text-center rounded-full px-2 text-${theme}-800`}
      style={{
        minWidth: 30,
        height: 30,
        lineHeight: "30px",
        display: "inline-block"
      }}
    >
      <input
        type="checkbox"
        checked={value}
        {...props}
        style={{ opacity: 0, position: "absolute" }}
        onChange={evt => props.onChange(evt.target.checked)}
        ref={ref}
      />
      <Icon name={value ? "check-square" : "square-o"} />
      {children && <span className="ml-2">{children}</span>}
    </label>
  );
}

export default forwardRef(Switch);
