import React, { useContext, useEffect, useState } from "react";
import { Input, FlexColumn, Spacer } from "@marg51/react-ui";
import { useFormContext } from "./Form";
import { Controller } from "react-hook-form";

export default function SelectForm({
  name,
  title = name,
  defaultValue = undefined,
  onChange = () => {},
  updateOnBlur,
  required,
  children,
  errorMessages = {},
  ...props
}) {
  if (updateOnBlur) console.warn("updateOnBlur: not reimplemented");

  const { control, errors } = useFormContext();

  const error = errors[name];
  return (
    <FlexColumn>
      <label
        className={`pl-2 font-semibold text-xs uppercase text-${
          error ? "red" : "gray"
        }-800`}
      >
        {title}
      </label>

      <Controller
        as={<select children={children} {...props} />}
        control={control}
        rules={{ required }}
        onChange={(value) => {
          return value[0].target.value;
        }}
        name={name}
        defaultValue={defaultValue}
      />

      {error && <Spacer size="small" />}
      {error && (
        <div
          className="bg-red-100 border border-red-500 text-red-700 rounded px-2 py-1"
          style={{ marginTop: -1 }}
        >
          {errorMessages[error.type] ||
            "an error occured with code: " + error.type}
        </div>
      )}
    </FlexColumn>
  );
}
