import React, { useContext, useEffect, useState } from "react";
import { Input, FlexColumn, Spacer, FlexRow } from "@marg51/react-ui";
import { useFormContext } from "./Form";

export default function CheckboxForm({
  defaultValue,
  required,
  title = name,
  name,
  className = "",
  ...props
}) {
  const { register, errors } = useFormContext();
  const [value, setValue] = useState(defaultValue);

  const error = errors[name];

  return (
    <FlexColumn>
      <label>
        <FlexRow nowrap>
          <input
            checked={value}
            type="checkbox"
            name={name}
            className={`border border-gray-300 rounded p-4 ${className}`}
            {...props}
            ref={register({ defaultValue, required })}
            onChange={event => {
              setValue(!value);
            }}
          />
          <Spacer size="small" />
          <div style={{ whiteSpace: "nowrap" }}>{title}</div>
        </FlexRow>
      </label>
      {error && <Spacer size="small" />}
      {error && (
        <div
          className="bg-red-100 border border-red-500 text-red-700 rounded px-2 py-1"
          style={{ marginTop: -1 }}
        >
          {error.type}
        </div>
      )}
    </FlexColumn>
  );
}
