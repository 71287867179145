import React from "react";
import Icon from "./Icon";

export default function IconButton({
  theme = "gray",
  onClick,
  className,
  size = 16,
  style,
  ...props
}) {
  return (
    <button
      className={`text-center bg-${theme}-300 text-${theme}-700 clickable hover:text-${theme}-800 shadow transition ${className}`}
      type="button"
      style={{
        borderRadius: "50%",
        width: size + 10,
        height: size + 10,
        lineHeight: size + 10 + "px",
        ...style
      }}
      onClick={onClick}
    >
      <Icon {...props} />
    </button>
  );
}
