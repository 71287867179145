import React, { forwardRef } from "react";

function InputUnstyled({ multiline = false, onChange, value, ...props }, ref) {
  function getValue(evt) {
    if (props.type === "file") {
      return evt.target.files;
    }

    return evt.target.value;
  }

  if (multiline) {
    return (
      <textarea
        ref={ref}
        {...props}
        value={value}
        onChange={evt => onChange(getValue(evt))}
      />
    );
  }
  return (
    <input
      ref={ref}
      {...props}
      value={value}
      onChange={evt => onChange(getValue(evt))}
    />
  );
}

export default forwardRef(InputUnstyled);
