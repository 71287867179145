import React, { forwardRef } from "react";

function FlexColumn({ style = {}, targetRef, ...props }, ref) {
  if (targetRef) {
    console.warn("FlexRow: please use ref instead of targetRef");
  }
  return (
    <div
      ref={targetRef || ref}
      style={{ display: "flex", flexDirection: "column", ...style }}
      {...props}
    />
  );
}

export default forwardRef(FlexColumn);
