import React, { useState, useEffect, useRef } from "react";
import classnames from "classnames";
import BaseIcon from "./Icon";
import "./Button.scss";

const Button = React.forwardRef((props, ref) => {
  const [status, setStatus] = useState(props.status || "normal");

  useEffect(() => {
    if (props.status && status !== props.status) {
      setStatus(props.status);
    }
  }, [props.status]);

  const {
    theme = "gray",
    children,
    icon,
    style = {},
    type = "primary",
    submit = false,
    onClick = () => {},
    status: propsStatus,
    className = "",
    ...rest
  } = props;
  const classes = classnames(
    "py-1",
    "transition",
    getClassesForThemeAndTypeAndStatus({ status, theme, type }),
    className,
    {
      "px-3": !icon,
      "pr-1 pl-3": icon,
    }
  );

  return (
    <button
      data-react-ui-button="button"
      type={submit ? "submit" : "button"}
      ref={ref}
      className={classes}
      style={{
        display: "flex",
        alignItems: "center",
        whiteSpace: "nowrap",
        ...style,
      }}
      onClick={(event) => {
        if (status === "normal") {
          const promise = onClick(event);

          if (promise && promise.then) {
            setStatus("loading");
            promise.then(
              () => setStatus(props.status || "normal"),
              () => setStatus(props.status || "normal")
            );
          }
        }
      }}
      {...rest}
    >
      {children}
      {icon && <Icon theme={theme} status={status} icon={icon} type={type} />}
    </button>
  );
});

const Icon = ({ theme, icon, type, status }) => {
  const classes = classnames("rounded", {
    ["bg-" + theme + "-400"]: type === "primary",
    ["text-" + theme + "-800"]: type === "primary",
  });

  return (
    <React.Fragment>
      <ShrinkableSpacer />
      <span
        className={classes}
        style={{
          width: 30,
          height: 30,
          lineHeight: "30px",
          flexShrink: 0,
          display: "inline-block",
          justifySelf: "flex-end",
        }}
      >
        <BaseIcon name={status === "loading" ? "spinner fa-spin" : icon} />
      </span>
    </React.Fragment>
  );
};

const ShrinkableSpacer = () => (
  <span
    style={{
      flexBasis: 15,
      minWidth: 10,
      flexGrow: 1,
      display: "inline-block",
    }}
  />
);

const colorMatrix = {
  primary: {
    active: {
      bg: 700,
      text: 200,
    },
    disabled: {
      bg: 400,
      text: 100,
    },
    loading: {
      bg: 900,
      text: 100,
    },
    normal: {
      bg: 600,
      text: 100,
    },
  },

  secondary: {
    active: {
      bg: 200,
      text: 700,
    },
    disabled: {
      bg: 100,
      text: 900,
    },
    loading: {
      bg: 100,
      text: 900,
    },
    normal: {
      bg: 100,
      text: 700,
    },
  },

  tertiary: {
    active: {
      bg: 700,
      text: 200,
    },
    disabled: {
      bg: 900,
      text: 100,
    },
    loading: {
      bg: 900,
      text: 100,
    },
    normal: {
      bg: 600,
      text: 100,
    },
  },
};

function getClassesForThemeAndTypeAndStatus({ theme, type, status }) {
  const bgColor = colorMatrix[type][status].bg;
  const textColor = colorMatrix[type][status].text;

  if (type === "primary") {
    return `shadow rounded bg-${theme}-${bgColor} text-${theme}-${textColor} ${status}`;
  }

  if (type === "secondary") {
    return `shadow rounded border border-${theme}-${textColor} bg-${theme}-${bgColor} text-${theme}-${textColor} ${status}`;
  }

  if (type === "tertiary") {
    return `hover:shadow underline rounded hover:bg-${theme}-${textColor} border-${theme}-${textColor} text-${theme}-${bgColor} ${status}`;
  }

  return "";
}
export default Button;
