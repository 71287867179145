import React, { useContext, useEffect, useState } from "react";
import { Input, FlexColumn, Spacer } from "@marg51/react-ui";
import { useFormContext } from "./Form";

export default function InputForm({
  name,
  title = name,
  defaultValue,
  required,
  updateOnBlur,
  regex = pattern,
  pattern,
  errorMessages = {},
  ...props
}) {
  if (updateOnBlur) console.warn("updateOnBlur: not reimplemented");
  if (regex) console.warn("regex: use pattern instead");
  const { register, errors } = useFormContext();

  const error = errors[name];

  return (
    <FlexColumn>
      <label
        className={`pl-2 font-semibold text-xs uppercase text-${
          error ? "red" : "gray"
        }-800`}
      >
        {title}
      </label>
      <Input
        onChange={() => {}}
        name={name}
        defaultValue={defaultValue}
        ref={register({ required, pattern, defaultValue })}
        {...props}
      />
      {error && <Spacer size="small" />}
      {error && (
        <div
          className="bg-red-100 border border-red-500 text-red-700 rounded px-2 py-1"
          style={{ marginTop: -1 }}
        >
          {errorMessages[error.type] ||
            "an error occured with code: " + error.type}
        </div>
      )}
    </FlexColumn>
  );
}
