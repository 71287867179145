import React, { createContext, useContext, useMemo } from "react";
const AlertContext = createContext();
const Alert = ({
  children,
  type = "",
  style = {},
  size = "",
  theme = "blue",
}) => {
  if (!theme) theme = type;

  const context = useMemo(() => ({ theme, size }), [theme, size]);
  return (
    <AlertContext.Provider value={context}>
      <div
        className={`shadow border-t-4 border-${theme}-500 bg-white text-left text-black`}
        style={{
          alignSelf: "center",
          marginTop: size === "small" ? 10 : 30,
          marginBottom: size === "small" ? 10 : 30,
          padding: size === "small" ? "10px" : "30px",
          display: "flex",
          alignItems: "center",
          ...style,
        }}
      >
        <div style={{ flex: 1 }}>{children}</div>
      </div>
    </AlertContext.Provider>
  );
};

export default Alert;

export const Title = ({ children, icon = "" }) => {
  const { theme, size } = useContext(AlertContext);

  return (
    <div
      className={`bg-${theme}-200 text-${theme}-900`}
      style={{
        margin:
          size === "small"
            ? "-10px -10px 10px -10px"
            : "-30px -30px 30px -30px",
        padding: size === "small" ? 10 : 30,
        fontSize: "1.2em",
      }}
    >
      {children}
    </div>
  );
};
export const Footer = ({ children }) => {
  const { size } = useContext(AlertContext);

  return (
    <div
      className={`bg-gray-200 border-t border-gray-400`}
      style={{
        margin: size === "small" ? "10px -10px -10px" : "30px -30px -30px",
        padding: size === "small" ? 10 : 30,
      }}
    >
      {children}
    </div>
  );
};

Alert.Title = Title;
Alert.Footer = Footer;
