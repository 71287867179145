import React from "react";
export default function Icon({ name, className = "", style = {}, ...props }) {
  if (!name) {
    throw new Error("<Icon/> is missing the property .name");
  }
  if (typeof name === "object") {
    return (
      <span className={`icon-component ${className}`} style={style} {...props}>
        {name}
      </span>
    );
  }
  if (name.match(/^https?:/)) {
    return (
      <img
        className={`${className} icon-svg`}
        style={style}
        {...props}
        src={name}
      />
    );
  }
  return (
    <i className={`${className} fa fa-${name}`} style={style} {...props} />
  );
}

Icon.Button = function IconButton({
  theme = "gray",
  onClick,
  className,
  size = 16,
  style = {},
  ...props
}) {
  return (
    <button
      className={`text-center bg-${theme}-400 text-${theme}-800 clickable hover:shadow transition ${className}`}
      type="button"
      style={{
        borderRadius: "50%",
        width: size + 10,
        height: size + 10,
        lineHeight: size + 10 + "px",
        ...style
      }}
      onClick={onClick}
    >
      <Icon {...props} />
    </button>
  );
};
