// @ts-check
import React, { useContext } from "react";
/**
 * @typedef { import("react-hook-form").FormContextValues } FormContextValues
 */

import { useForm } from "react-hook-form";

const context = React.createContext();

/**
 * @return {FormContextValues}
 */
export const useFormContext = () => useContext(context);

export default function MyForm({
  onSubmit,
  children,
  defaultValues = {},
  options = {}
}) {
  const formContext = useForm({
    // mode: "onSubmit",
    // reValidateMode: "onChange",
    defaultValues,
    // validationResolver: undefined,
    // validationContext: undefined,
    // validateCriteriaMode: "firstErrorDetected",
    // submitFocusError: true,
    // nativeValidation: false
    ...options
  });

  return (
    <context.Provider value={formContext}>
      <form
        onSubmit={event => {
          formContext.handleSubmit(onSubmit)(event);

          event.stopPropagation();
          event.preventDefault();
        }}
      >
        {children}
      </form>
    </context.Provider>
  );
}
