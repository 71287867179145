import React, { forwardRef } from "react";
import Icon from "./Icon";
import "./Button.scss";

export default forwardRef(function ButtonIcon(
  { icon, theme, onClick, style, className = "" },
  ref
) {
  return (
    <div
      data-react-ui-button="icon"
      style={{
        minWidth: 30,
        minHeight: 30,
        width: 30,
        height: 30,
        lineHeight: "30px",
        ...style,
      }}
      ref={ref}
      className={`clickable transition shadow rounded text-center bg-${theme}-300 text-${theme}-700 ${className}`}
      onClick={onClick}
    >
      <Icon name={icon} />
    </div>
  );
});
