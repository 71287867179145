import React, { forwardRef } from "react";

function Checkbox(
  { className = "", parentStyle, children, value, ...props },
  ref
) {
  return (
    <label style={parentStyle} className="clickable">
      <input
        type="checkbox"
        className={`border border-gray-300 rounded p-4 ${className}`}
        {...props}
        onChange={evt => props.onChange(evt.target.checked)}
        ref={ref}
      />
      {children}
    </label>
  );
}
export default forwardRef(Checkbox);
