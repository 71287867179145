import React, { useContext, useEffect, useState } from "react";
import { useFormContext } from "./Form";
import { Controller } from "react-hook-form";

export default function HiddenInputForm({
  name,
  title = name,
  defaultValue = undefined,
  onChange = () => {},
  required,
  regex = pattern,
  pattern,
  ...props
}) {
  const { control } = useFormContext();

  return (
    <Controller
      as={() => null}
      control={control}
      rules={{ required, pattern }}
      name={name}
      defaultValue={defaultValue}
    />
  );
}
