import React from "react"
import Spacer from "./Spacer"

export default function Divider({ theme = "gray", size = "", style = {} }) {
  const count = size === "small" ? 1 : size === "large" ? 3 : 2
  return (
    <React.Fragment>
      {count === 3 && <Spacer />}
      {count >= 2 && <Spacer />}
      <Spacer />
      <div
        className={`bg-${theme}-300`}
        style={{
          flexBasis: "1px",
          flexShrink: 0,
          alignSelf: "stretch",
          ...style,
        }}
      />
      <Spacer />
      {count >= 2 && <Spacer />}
      {count === 3 && <Spacer />}
    </React.Fragment>
  )
}
