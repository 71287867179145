import { Component } from "react"
import ReactDOM from "react-dom"

export default class OnOutsideClick extends Component {
    handler = event => {
        if (this.props.disabled) {
            return
        }

        try {
            const ownElement = ReactDOM.findDOMNode(this)
            if (ownElement && !ownElement.contains(event.target)) {
                this.props.handler(event)
            }
        } catch (e) {
            console.error("an error occured in onOutsideClick", e.message)
        }
    }
    componentDidMount() {
        document.addEventListener("click", this.handler, false)
    }
    componentWillUnmount() {
        document.removeEventListener("click", this.handler, false)
    }
    render() {
        return this.props.children
    }
}
