export const get = (url, params = {}, queryParams = {}) =>
  _fetch(url, params, queryParams);
export const post = (url, data, params = {}) => {
  return _fetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    ...params
  });
};
export const uploadFiles = (url, data, params = {}) => {
  const formdata = new FormData();
  Object.entries(data).map(([name, value]) => {
    formdata.append(name, value);
  });
  return _fetch(url, {
    method: "POST",
    body: formdata,
    ...params
  });
};
export const remove = (url, data, params = {}) => {
  return _fetch(url, {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    ...params
  });
};
export const patch = (url, data, params = {}) => {
  return _fetch(url, {
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    ...params
  });
};

function _fetch(url, params = {}, queryParams = null) {
  let queryString = "";
  if (queryParams) {
    const searchParams = new URLSearchParams();
    Object.keys(queryParams).forEach(key =>
      searchParams.append(key, queryParams[key])
    );
    queryString = "?" + searchParams.toString();
  }

  if (queryString === "?") queryString = "";

  const request = new Request(url + queryString, {
    method: "GET",
    headers: new Headers(),
    ...params
  });

  // request.headers.append("Authorization", localStorage.getItem("van.user.token"))

  return fetch(request).then(handleResponse);
}

function handleResponse(response) {
  if (response.status >= 400) {
    if (response.status >= 500) {
      return Promise.reject({
        status: response.status,
        errors: [response.statusText]
      });
    }

    console.log("failed to fetch", response);
    try {
      return response
        .json()
        .then(error =>
          Promise.reject({
            errors: error.errors || error,
            status: response.status
          })
        );
    } catch (e) {
      return Promise.reject({ errors: null, status: response.status });
    }
  }

  return response.json().catch(data => {
    console.log("couldn't parse json from query");
    return Promise.reject(data);
  });
}
