import React, {
  useMemo,
  useReducer,
  useContext,
  createContext,
  useEffect
} from "react";
import * as palet from "./palet";

const TabsContext = createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case "add":
      return {
        ...state,
        selectedName: state.selectedName || action.tab.name,
        tabs: [...state.tabs, action.tab]
      };
    case "remove":
      const tabs = state.tabs.filter(({ name }) => name !== action.tab.name);

      return {
        ...state,
        selectedName:
          state.selectedName !== action.tab.name
            ? state.selectedName
            : tabs.length && tabs[0].name,
        tabs
      };
    case "select":
      return {
        ...state,
        selectedName: action.value
      };
    default:
      return state;
  }
};

const Tabs = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, { tabs: [] });

  const context = useMemo(
    () => ({
      addTab: tab => {
        dispatch({ type: "add", tab });
      },
      removeTab: name => {
        dispatch({ type: "remove", tab: { name } });
      },
      select: name => {
        dispatch({ type: "select", value: name });
      },
      isSelected: name => {
        return state.selectedName === name;
      }
    }),
    [state]
  );

  return (
    <TabsContext.Provider value={context}>
      <div
        style={{
          display: "flex",
          flexShrink: 0,
          borderBottom: "1px solid " + palet.gray._300,
          marginBottom: 10,
          overflowX: "auto"
        }}
      >
        {state.tabs.map((tab, idx) => (
          <div
            style={{
              borderRight:
                idx < state.tabs.length - 1
                  ? "1px solid " + palet[tab.theme]._300
                  : undefined,
              borderBottom: context.isSelected(tab.name)
                ? "3px solid " + palet[tab.theme]._500
                : undefined,
              flex: 1,
              padding: 10,
              textAlign: "center",
              background: context.isSelected(tab.name)
                ? palet[tab.theme]._100
                : "white",
              cursor: context.isSelected(tab.name) ? "default" : "pointer",
              color: context.isSelected(tab.name)
                ? palet[tab.theme]._900
                : palet.gray._700
            }}
            onClick={() => context.select(tab.name)}
          >
            {tab.renderName}
          </div>
        ))}
      </div>
      {children}
    </TabsContext.Provider>
  );
};

export const Tab = ({
  name,
  renderName = name,
  theme = "primary",
  children
}) => {
  const context = useContext(TabsContext);

  useEffect(() => {
    context.addTab({ name, theme, renderName });

    return () => context.removeTab(name);
  }, []);

  return context.isSelected(name) ? children : null;
};

export default Tabs;

Tabs.Tab = Tab;
