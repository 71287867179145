import React, { useContext } from "react";
import { Button } from "@marg51/react-ui";

import { useFormContext } from "./Form";

export default function SubmitButton({
  onClick = () => {},
  theme = "blue",
  ...props
}) {
  const { errors } = useFormContext();

  console.log({ errors });
  return <Button theme={theme} submit disabled={false} {...props} />;
}
