import React, { forwardRef } from "react";

function FlexRow({ style = {}, nowrap = false, targetRef, ...props }, ref) {
  if (targetRef) {
    console.warn("FlexRow: please use ref instead of targetRef");
  }
  if (props.justifyContent) {
    throw new Error("FlexRow: justifyContent has been removed");
  }
  return (
    <div
      ref={targetRef || ref}
      style={{
        display: "flex",
        flexShrink: 0,
        flexWrap: nowrap ? "nowrap" : "wrap",
        ...style
      }}
      {...props}
    />
  );
}

export default forwardRef(FlexRow);
