// @ts-check
import React, {
  Suspense,
  useEffect,
  useContext,
  createContext,
  useRef,
} from "react";
import { createPortal } from "react-dom";

let div;
if (typeof window !== "undefined") {
  div = document.createElement("div");
  div.id = "ui_modal";

  document.body.appendChild(div);
}

let MODALS_OPEN = [];
const ModalContext = createContext();
export default function Modal({
  isOpen,
  children,
  theme = "blue",
  onRequestClose,
  size = "",
  style,
}) {
  const thisDocument =
    typeof window !== "undefined"
      ? window.document
      : { body: { classList: { add: noop, remove: noop } } };

  const ref = useRef(Math.random());

  useEffect(() => {
    if (isOpen) {
      thisDocument.body.classList.add("modalOpen");
      if (MODALS_OPEN.length === 0) {
        thisDocument.body.classList.add("react-ui-modal-is-open");
      }
      if (!MODALS_OPEN.includes(ref.current)) {
        MODALS_OPEN.push(ref.current);
      }
    } else {
      thisDocument.body.classList.remove("modalOpen");
      if (MODALS_OPEN.includes(ref.current)) {
        MODALS_OPEN = MODALS_OPEN.filter((_) => _ !== ref.current);
      }
      if (MODALS_OPEN.length === 0) {
        thisDocument.body.classList.remove("react-ui-modal-is-open");
      }
    }

    return () => {
      thisDocument.body.classList.remove("modalOpen");
      if (MODALS_OPEN.includes(ref.current)) {
        MODALS_OPEN = MODALS_OPEN.filter((_) => _ !== ref.current);
      }
      if (MODALS_OPEN.length === 0) {
        thisDocument.body.classList.remove("react-ui-modal-is-open");
      }
    };
  }, [!!isOpen]);

  const content = (
    <ModalContext.Provider value={theme}>
      <Suspense maxDuration={350} fallback="loading …">
        <div
          onClick={(event) => {
            event.stopPropagation();
            onRequestClose();
          }}
          style={{
            display: isOpen ? "block" : "none",
            zIndex: 20,
            background: "rgba(112, 112, 112, 0.9)",
            position: "fixed",
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            overflow: "auto",
            paddingBottom: 30,
          }}
        >
          <div
            onClick={(event) => event.stopPropagation()}
            className={`shadow bg-white text-black border-t-4 border-${theme}-500 p-8`}
            style={{
              maxWidth: size === "large" ? 980 : 680,
              margin: "40px auto",
              ...style,
            }}
          >
            {children}
          </div>
        </div>
      </Suspense>
    </ModalContext.Provider>
  );

  return createPortal(content, div);
}

export const ModalTitle = ({ children }) => {
  const theme = useContext(ModalContext);
  return (
    <div
      style={{
        margin: "-2rem -2rem 2rem -2rem",
        fontSize: "1.2em",
      }}
      className={`p-8 bg-${theme}-100 text-${theme}-900`}
    >
      {children}
    </div>
  );
};
export const ModalFooter = ({ children }) => {
  return (
    <div
      style={{
        margin: "2rem -2rem -2rem -2rem",
      }}
      className="p-8 border-t border-gray-300 bg-gray-200"
    >
      {children}
    </div>
  );
};

Modal.Title = ModalTitle;
Modal.Footer = ModalFooter;

function noop() {}
