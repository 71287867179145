import React from "react";
import Icon from "./Icon";
import FlexRow from "./FlexRow";

export default function Toggle({ theme = "blue", value, children, ...props }) {
  return (
    <label
      className={`clickable transition rounded-full ${
        value ? `bg-${theme}-500` : `bg-gray-300`
      }`}
      style={{
        width: 45,
        height: 24,
        lineHeight: "24px",
        display: "inline-block",
        padding: "0 2px"
      }}
    >
      <FlexRow>
        <input
          type="checkbox"
          checked={value}
          {...props}
          style={{ opacity: 0, position: "absolute" }}
          onChange={evt => props.onChange(evt.target.checked)}
        />
        <div className="transition" style={{ flex: value ? 1 : 0 }} />
        <span
          className={`transition clickable bg-white`}
          style={{
            display: "inline-block",
            width: 20,
            height: 20,
            borderRadius: "50%",
            marginTop: 2
          }}
        />
      </FlexRow>
    </label>
  );
}
